.contact{
    margin: 0px auto 80px auto;
    width: 90%;
    /* max-height: 300px; */
    display: flex;
    align-content: center;
    justify-content: space-between;
   
}
.contact-col{
    flex-basis: 48%;
    color: #a65320;
}
.contact-col h2{
    color: black;
    padding-bottom: 1px;
}
.contact-col h3{
    color: black;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 1px;
}
.contact-col h3 img{
    width: 35px;
    margin-left: 10px;
}
.contact-col p{
    max-width: 350px;
    list-style: 0.3;
}
.contact-col ul li{
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.contact-col ul li img{
    width: 25px;
    margin-right: 10px;
}

.contact form input,.contact form textarea{
    display: block;
    width: 100%;
    background: #eac5afad;
    color: white;
    padding: 8px;
    border: 0;
    outline: 0;
    margin-bottom: 15px;
    margin-top: 5px;
    resize: none;
}
.contact-col span{
    display: block;
    margin: 20px 0;
}
.cnt-btn{
    background-color: #a65320;
}
/*======== media querry =========*/

@media (max-width: 700px){
    .contact{
        display: block;
    }
    .contact-col:nth-child(1){
        padding: 20px;
        margin-bottom: 50px;
    }
        .floating-icons1 {
            /* height: max-content; */
            display: flex;
            flex-direction: column;
           align-items: center;
           gap: 20px;
        }
}
