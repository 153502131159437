.campus {
    margin: 80px auto;
    width: 90%;
    text-align: center;
    position: relative;
}

/* Carousel Styling */
.carousel {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
}

.carousel-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    cursor: pointer;
}

.carousel-item {
    width: 250px;
    height: 180px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
}

.carousel-item:hover {
    transform: scale(1.05);
}

/* Navigation Buttons */
.prev-button,
.next-button {
    background-color: #a65320;
    border: none;
    color: white;
    font-size: 24px;
    padding: 10px 20px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    z-index: -0;
}

.prev-button {
    left: 0;
}

.next-button {
    right: 0;
}

/* Modal (Full-Screen on Mobile) */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.modal-content {
    position: relative;
}

.modal-content img {
    max-width: 50%;
    max-height: 50%;
    object-fit: contain;
}

/* Close Button */
.close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
}

/* Full-screen image for mobile */
@media (max-width: 768px) {
    .prev-button {
            left: -25px;
            font-size: 17px;
                padding: 10px 17px;
            /* Move outside the image */
        }
    
        .next-button {
            right: -25px;
            font-size: 17px;
                padding: 10px 17px;
            /* Move outside the image */
        }
    .modal-content img {
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        object-fit: cover;
    }

    .close {
        top: -40px;
        right: 10px;
        font-size: 26px;
    }
}