.hero {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(rgba(8, 0, 58, 0.3), rgba(8, 0, 58, 0.4)), url('../../assets/CollegeBuilding.png');
    background-size: cover;
    background-position: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* Added to stack elements vertically */
}

.hero-text {
    text-align: center;
    max-width: 800px;
    padding-top: 150px;
}

.hero-text h1 {
    font-size: 60px;
    font-weight: 600;
}

.hero-text p {
    max-width: 700px;
    margin: 10px auto 20px;
    line-height: 1.4;
}

/* Notice Styles */
.notice {
    width: 100%;
    background-color: rgba(239, 236, 236, 0.466);
    position: absolute;
    top: 100px;
    left: 0;
    overflow: hidden;
    /* Hide overflow */
}

.notice-content {
    white-space: nowrap;
    /* Prevent wrapping */
    animation: scroll 20s linear infinite;
    /* Continuous scrolling */
    color: black;
    /* background-color: white; */
    padding: 10px 0;
    animation-play-state: running;
}
.notice-content:hover{
    animation-play-state: paused;
}
/* Animation Keyframes */
@keyframes scroll {
    0% {
        transform: translateX(100%);
        /* Start from right */
    }

    100% {
        transform: translateX(-100%);
        /* Move to left */
    }
}

/*======== media query =========*/

@media (max-width: 850px) {
    .hero-text h1 {
        font-size: 40px;
    }
}

@media (max-width: 650px) {
    .hero-text h1 {
        font-size: 30px;
        max-width: 400px;
        margin: auto;
    }

    .hero-text p {
        font-size: 14px;
        margin: 15px auto 30px;
    }
}